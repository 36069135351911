body {
  margin: 0;
  font-family: 'BasierCircle', 'Arial', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'BasierCircle', 'Arial', 'sans-serif';
}

.hoverableDiv:hover {
  cursor: pointer;
  text-decoration: underline;
}

/*.tableTitle:focus, .tableTitle:hover {
  color:transparent;
}
.tableTitle:focus:after,.tableTitle:hover:after{
    content:attr(data-text);
    overflow: visible;
    text-overflow: inherit;
    background: #fff;
    position: absolute;
    left:auto;
    top:auto;
    width: auto;
    max-width: 20rem;
    border: 1px solid #eaebec;
    padding: 0 .5rem;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.28);
    white-space: normal;
    word-wrap: break-word;
    display:block;
    color:black;
    margin-top:-1.25rem;
  }*/